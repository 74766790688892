/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/cloudinary_js@2.3.0/js/jquery.cloudinary.min.js
 * - /npm/bootstrap@4.0.0/dist/js/bootstrap.bundle.min.js
 * - /npm/@fancyapps/fancybox@3.3.5/dist/jquery.fancybox.min.js
 * - /npm/balance-text@3.2.0/balancetext.min.js
 * - /npm/flatpickr@4.5.0/dist/flatpickr.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
